import { sendGAEvent } from '@lib/ga4'

export type GAProps = {
	name: string
	key?: string
	value?: string
}

const handleGAEvent = ({ name, key, value }: GAProps) => {
	const eventParam = key && value ? { [key]: value } : {}
	console.log(name, key, value, eventParam)
	sendGAEvent(name, eventParam)
}

export default handleGAEvent
